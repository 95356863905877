import { useState, useEffect, useRef } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DialogContentText } from "@mui/material";
import Table from "@mui/material/Table";
import Typography from "@mui/material/Typography";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getAllSettingsQql } from "../../../services/query.service";
import styled from "@emotion/styled";
import { converDateTimeToPst } from "../../../services/util.service";
import ApiAction from "../../Common/ApiAction";
import { updateCacheSettingGql } from "../../../services/mutation.service";
import ConfirmDialog from "../../Common/RestoreRedisConfirmDialog";

const StyledTableCell = styled(TableCell)<any>(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: "bold",
    fontSize: 16,
  },
}));
export default function SettingsCache() {
  const [cacheName, setCacheName] = useState("");

  const confirmDialogRef = useRef();
  const currentSettingKey = useRef<string | null>(null);

  const [allSettings, setAllSettings] = useState(Array());
  const [getAllSettings] = useLazyQuery(getAllSettingsQql);
  useEffect(() => {
    getAllSettings({
      onCompleted: (data) => {
        setAllSettings(data?.getAllSettings?.payload?.allSettings || []);
      },
    });
  }, []);
  const [update, { loading: isLoading }] = useMutation(updateCacheSettingGql, {
    variables: {
      input: {
        cacheName: cacheName,
      },
    },
    onCompleted: (data: any) => {
      const newAllSettings = allSettings.map(obj =>
        (obj.key === cacheName || cacheName === "ALLSETTINGS") ? { ...obj, lastRun: data?.updateCacheSetting?.payload?.lastRun } : obj
      );
      setAllSettings(newAllSettings);
      setCacheName("");
    },
    onError: (err: any) => {
      setCacheName("");
    },
  });
  useEffect(() => {
    if (cacheName) update();
  }, [cacheName]);

  function renderConfirmationDialog() {
    return (
      <ConfirmDialog
        title="Restore Redis Configuration"
        content={
          <>
            <DialogContentText variant="body1">
              Restore Redis Configuration will remove the current data from Redis Repository.
            </DialogContentText>
            <DialogContentText variant="body1">
              This action cannot be undone.
            </DialogContentText>
            <DialogContentText variant="body1" fontStyle="italic">
              To confirm this, type the password
            </DialogContentText>
          </>
        }
        okText="Confirm"
        ref={confirmDialogRef}
        okHandler={() => {
          if (!!isLoading) {
            return;
          }

          if (currentSettingKey.current == null) {
            return;
          }
          setCacheName(currentSettingKey.current);
        }}
      />
    );
  }

  return (
    <>
      {renderConfirmationDialog()}
      <Typography variant="h4" component="h1" sx={{ fontWeight: "600", mb: 2 }}>
        Restore Redis Configuration
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="Restore Redis Configuration">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell width="25%">Last Run</StyledTableCell>
              <StyledTableCell width="5%" align="center">
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allSettings?.map((setting, index) => {
              return (
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} key={`setting${index}`}>
                  <TableCell>
                    {setting.key}
                  </TableCell>
                  <TableCell>{setting.lastRun ? converDateTimeToPst(+setting.lastRun) : '-'}</TableCell>
                  <TableCell align="center">
                    <ApiAction key={`btnSetting${index}`}
                      onClick={() => {
                        currentSettingKey.current = setting.key;
                        (confirmDialogRef?.current as any).setOpen(true);
                      }}
                      loading={!!isLoading}
                      disabled={!!isLoading}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
