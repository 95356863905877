import { useImperativeHandle, forwardRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';

const confirmationPassword = process.env.REACT_APP_RESTORE_REDIS_CONFIGURATION_CONFIRMMATION_PASSWORD ?? 'restore';

export interface RestoreRedisConfirmDialogProps {
  okHandler?: Function;
  content?: string | React.ReactNode;
  title: string | React.ReactNode;
  okText: string | React.ReactNode;
};

function RestoreRedisConfirmDialog(
  props: RestoreRedisConfirmDialogProps,
  ref: any
) {
  const { okHandler, okText, content, title } = props;
  const [open, setOpen] = useState(false);
  const [errorPassword, setErrorPassword] = useState({
    error: false,
    helperText: '',
  });

  function handleClose() {
    setOpen(false);

    // Reset password error
    setErrorPassword({
      error: false,
      helperText: '',
    });
  };

  useImperativeHandle(ref, () => ({
    setOpen,
  }));

  function verifyPassword(password: string): boolean {
    if (password == null || password.trim() === '') {
      setErrorPassword({
        error: true,
        helperText: 'Please type the password',
      });

      return false;
    }

    if (password !== confirmationPassword) {
      setErrorPassword({
        error: true,
        helperText: 'Invalid password',
      });
      return false;
    }

    return true;
  }

  async function handleOnSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());

    const { password } = formJson;
    const isPasswordValid = await verifyPassword(password);
    if (!isPasswordValid) {
      return;
    }

    if (okHandler) {
      okHandler();
    }

    handleClose();
  }

  return (
    <Dialog
      open={open}
      maxWidth='md'
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: handleOnSubmit,
      }}
    >
      <DialogTitle variant='h5' fontWeight="bold">{title ?? ''}</DialogTitle>
      <DialogContent>{content ?? ''}</DialogContent>
      <DialogActions>
        <Stack
          sx={{ width: '100%', padding: 2 }}
          spacing={2}
          direction="row"
          alignItems="baseline"
          justifyContent="center"
          >
          <TextField
            type="password"
            name="password"
            label="*Password"
            autoComplete="current-password"
            fullWidth
            error={errorPassword.error}
            helperText={errorPassword.helperText}
          />

          <Button
            type="submit"
            sx={{ padding: 2 }}
            fullWidth
            disableElevation
            variant="contained"
            color="error"
            size="large"
          >
            {okText ?? ''}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(RestoreRedisConfirmDialog);
