import { useState, useRef, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import Table from "@mui/material/Table";
import Typography from "@mui/material/Typography";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ConfirmDialog from "../../Common/ConfirmDialog";
import { getJobStatQql } from "../../../services/query.service";
import styled from "@emotion/styled";
import HeroBanner from "../ItemAction/RedisCacheHeroBanner";
import StoryTelling from "../ItemAction/RedisCacheStoryTelling";
import TabsWithTitle from "../ItemAction/RedisCacheTabsWithTitle";
import BeerClubRelease from "../ItemAction/RedisCacheBeerClubRelease";
import AemFe from "../ItemAction/WebCacheAemFe";
import UpdateMenuItem from "../ItemAction/RedisCacheMenuItemAllRestaurants";
import UpdateMenuItemCacheForMultiRestaurants from "../ItemAction/RedisCacheMenuItemMultiRestaurants";
import { converDateTimeToPst } from "../../../services/util.service";
import InputAndConfirmDialog from "../../Common/InputAndConfirmDialog";
import { CacheActionRef } from "../../Common/CacheActionRef";

const StyledTableCell = styled(TableCell)<any>(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: "bold",
    fontSize: 16,
  },
}));

export default function DashboardCachePurge() {
  const confirmDialogRef = useRef();
  const inputConfirmDialogRef = useRef();
  const cacheActionHeroBannerRef = useRef<CacheActionRef>();
  const cacheActionStoryTellingRef = useRef<CacheActionRef>();
  const cacheActionTabsWithTitleRef = useRef<CacheActionRef>();
  const cacheActionBeerClubReleaseRef = useRef<CacheActionRef>();
  const cacheActionAemFeRef = useRef<CacheActionRef>();
  const cacheProductAndMenuItemMultiRestaurantsRef = useRef<CacheActionRef>();
  const cacheProductAndMenuItemRef = useRef<CacheActionRef>();
  const [isBusy, setIsBusy] = useState(false);
  let currentRef: any;

  const [queryJobStats] = useLazyQuery(getJobStatQql);
  useEffect(() => {
    queryJobStats({
      onCompleted: (data) => {
        cacheActionAemFeRef?.current?.setStatus((prev: any) => {
          return {
            ...prev,
            lastRun: converDateTimeToPst(
              +data?.clearCacheCloudfront?.payload?.lastRun || ""
            ),
          };
        });
        cacheActionHeroBannerRef?.current?.setStatus((prev: any) => {
          return {
            ...prev,
            lastRun: converDateTimeToPst(
              +data?.heroBanner?.payload?.lastRun || ""
            ),
          };
        });
        cacheActionStoryTellingRef?.current?.setStatus((prev: any) => {
          return {
            ...prev,
            lastRun: converDateTimeToPst(
              +data?.storyTelling?.payload?.lastRun || ""
            ),
          };
        });
        cacheActionTabsWithTitleRef?.current?.setStatus((prev: any) => {
          return {
            ...prev,
            lastRun: converDateTimeToPst(
              +data?.tabsWithTitle?.payload?.lastRun || ""
            ),
          };
        });
        cacheActionBeerClubReleaseRef?.current?.setStatus((prev: any) => {
          return {
            ...prev,
            lastRun: converDateTimeToPst(
              +data?.beerClubRelease?.payload?.lastRun || ""
            ),
          };
        });
        cacheProductAndMenuItemRef?.current?.setStatus((prev: any) => {
          return {
            ...prev,
            lastRun: converDateTimeToPst(
              +data?.productAndMenu?.payload?.lastRun || ""
            ),
          };
        });
        cacheProductAndMenuItemMultiRestaurantsRef?.current?.setStatus(
          (prev: any) => {
            return {
              ...prev,
              lastRun: converDateTimeToPst(
                +data?.productAndMenuMultiRestaurants?.payload?.lastRun || ""
              ),
            };
          }
        );
      },
    });
  }, []);
  function renderConfirmationDialog() {
    return (
      <ConfirmDialog
        title="Are you sure to clear cache?"
        okText="Yes"
        cancelText="No"
        ref={confirmDialogRef}
        okHandler={() => {
          if (currentRef) {
            setIsBusy(true);
            currentRef.action();
          }
        }}
      ></ConfirmDialog>
    );
  }
  function renderInputConfirmationDialog() {
    return (
      <InputAndConfirmDialog
        title="Are you sure to clear cache?"
        okText="Yes"
        cancelText="No"
        ref={inputConfirmDialogRef}
        okHandler={(input: string) => {
          if (currentRef) {
            setIsBusy(true);
            currentRef.action({
              variables: {
                input: {
                  restaurantIds: input,
                },
              },
            });
          }
        }}
      ></InputAndConfirmDialog>
    );
  }
  return (
    <>
      {renderConfirmationDialog()}
      {renderInputConfirmationDialog()}
      <Typography variant="h4" component="h1" sx={{ fontWeight: "600", mb: 2 }}>
        Cache Purge
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="Cache Purge">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell width="25%">Last Run</StyledTableCell>
              <StyledTableCell width="15%">Status</StyledTableCell>
              <StyledTableCell width="5%" align="center">
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <AemFe
              ref={cacheActionAemFeRef}
              isBusy={isBusy}
              onClick={() => {
                (confirmDialogRef?.current as any).setOpen(true);
                currentRef = cacheActionAemFeRef.current;
              }}
              onCompleted={() => {
                setIsBusy(false);
              }}
            />
            <HeroBanner
              ref={cacheActionHeroBannerRef}
              isBusy={isBusy}
              onClick={() => {
                (confirmDialogRef?.current as any).setOpen(true);
                currentRef = cacheActionHeroBannerRef.current;
              }}
              onCompleted={() => {
                setIsBusy(false);
              }}
            />
            <StoryTelling
              ref={cacheActionStoryTellingRef}
              isBusy={isBusy}
              onClick={() => {
                (confirmDialogRef?.current as any).setOpen(true);
                currentRef = cacheActionStoryTellingRef.current;
              }}
              onCompleted={() => {
                setIsBusy(false);
              }}
            />
            <TabsWithTitle
              ref={cacheActionTabsWithTitleRef}
              isBusy={isBusy}
              onClick={() => {
                (confirmDialogRef?.current as any).setOpen(true);
                currentRef = cacheActionTabsWithTitleRef.current;
              }}
              onCompleted={() => {
                setIsBusy(false);
              }}
            />
            <BeerClubRelease
              ref={cacheActionBeerClubReleaseRef}
              isBusy={isBusy}
              onClick={() => {
                (confirmDialogRef?.current as any).setOpen(true);
                currentRef = cacheActionBeerClubReleaseRef.current;
              }}
              onCompleted={() => {
                setIsBusy(false);
              }}
            />
            <UpdateMenuItemCacheForMultiRestaurants
              hidden={true}
              ref={cacheProductAndMenuItemMultiRestaurantsRef}
              isBusy={isBusy}
              onClick={() => {
                (inputConfirmDialogRef?.current as any).setOpen(true);
                currentRef = cacheProductAndMenuItemMultiRestaurantsRef.current;
              }}
              onCompleted={() => {
                setIsBusy(false);
              }}
            />
            <UpdateMenuItem
              hidden={true}
              ref={cacheProductAndMenuItemRef}
              isBusy={isBusy}
              onClick={() => {
                (confirmDialogRef?.current as any).setOpen(true);
                currentRef = cacheProductAndMenuItemRef.current;
              }}
              onCompleted={() => {
                setIsBusy(false);
              }}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
