export function setUserData(data: any) {
  window && window.localStorage.setItem("BJS_USERNAME", data.username);
  window && window.localStorage.setItem("BJS_TOKEN", data.token);
}

export function clearUserData() {
  setUserData({
    username: "",
    token: "",
  });
}

export function getUserData() {
  const userData = {
    username: window ? window.localStorage.getItem("BJS_USERNAME") : "",
    token: window ? window.localStorage.getItem("BJS_TOKEN") : "",
  };
  return userData;
}
export function converDateTimeToPst(now?: any) {
  const dateTime = now ? new Date(now) : new Date();
  return dateTime.toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
  });
}
export function formatSuccessData(data: any) {
  return {
    ...data,
    lastRun: converDateTimeToPst(),
    value: "success",
    message: "ok",
  };
}

export function formatErrorData(err: any) {
  return {
    lastRun: converDateTimeToPst(),
    value: "error",
    message: err.message,
  };
}

const methods = {
  setUserData,
  getUserData,
  formatSuccessData,
  formatErrorData,
};
export default methods;
