import { gql } from "@apollo/client";
export const purgeCacheGql = gql`
  mutation ClearCache {
    clearCacheAem {
      success
      code
      error {
        code
        message
      }
      payload {
        lastRun
        metadata {
          attempts
          httpStatusCode
          requestId
          totalRetryDelay
        }
        Location
        Invalidation {
          CreateTime
          Id
          Status
          InvalidationBatch {
            Paths {
              Items
              Quantity
            }
            CallerReference
          }
        }
      }
    }
    clearCacheFe {
      success
      code
      error {
        code
        message
      }
      payload {
        lastRun
        metadata {
          attempts
          httpStatusCode
          requestId
          totalRetryDelay
        }
        Location
        Invalidation {
          CreateTime
          Id
          Status
          InvalidationBatch {
            Paths {
              Items
              Quantity
            }
            CallerReference
          }
        }
      }
    }
  }
`;

export const updateCacheGqlV3 = gql`
  mutation UpdateCacheV3($input: UpdateCacheV3Input) {
    updateCacheV3(input: $input) {
      code
      success
      error {
        code
        message
      }
      payload {
        message
        lastRun
      }
    }
  }
`;

export const loginGql = gql`
  mutation Login($input: SystemUserLoginInput) {
    login(input: $input) {
      success
      code
      error {
        code
        message
      }
      payload {
        username
        token
      }
    }
  }
`;
export const updateMenuCacheGqlV3 = gql`
  mutation updateMenuCache {
    updateMenuCacheV3 {
      success
      code
      error {
        code
        message
      }
      payload {
        message
        lastRun
      }
    }
  }
`;
export const updateMenuMultiRestaurantsCacheGqlV3 = gql`
  mutation updateMenuMultiRestaurantsCache($input: UpdateMenuCacheV3Input) {
    updateMenuCacheV3(input: $input) {
      success
      code
      error {
        code
        message
      }
      payload {
        message
        lastRun
      }
    }
  }
`;
export const updateCacheSettingGql = gql`
  mutation updateCacheSetting($input: UpdateCacheSettingInput) {
    updateCacheSetting(input: $input) {
      success
      code
      error {
        code
        message
      }
      payload {
        message
        lastRun
      }
    }
  }
`;

const exportGql = {
  loginGql,
  purgeCacheGql,
  updateCacheGqlV3,
  updateMenuCacheGqlV3,
  updateMenuMultiRestaurantsCacheGqlV3,
  updateCacheSettingGql,
};

export default exportGql;
